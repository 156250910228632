import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '../layouts/DashboardLayout.vue';
import { authGuard } from '../utils/authGuard';
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/dashboard',
    component: DashboardLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotificationView.vue')
      },
      {
        path: '/packages',
        name: 'Packages',
        component: () => import(/* webpackChunkName: "about" */ '../views/PackageView.vue')
      },
      {
        path: '/transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "about" */ '../views/TransactionsView.vue')
      },
      {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () => import(/* webpackChunkName: "about" */ '../views/WithdrawalView.vue')
      },
      
    ],
  },
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   beforeEnter: authGuard,

  //   component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
