<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list>
        <v-row align="center" no-gutters class="justify-center">
          <v-col class="d-flex" cols="auto">
            <v-list-item-avatar>
              <img src="../assets/lg.png" alt="Speedy Logo" height="60px" />
            </v-list-item-avatar>
          </v-col>
          <!-- <v-col class="d-flex ml-2" cols="auto">
            <v-list-item-title class="title text-uppercase speedy-theme">
              MultiMilla
            </v-list-item-title>
          </v-col> -->
        </v-row>

        <v-divider></v-divider>

        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          to="/dashboard"
          exact
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-bell-outline"
          to="/notifications"
          title="Notifications"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-plus-circle-multiple-outline"
          title="Investments"
          to="/packages"
          exact
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-format-list-bulleted"
          title="Transactions"
          to="/transactions"
          exact
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-clipboard-text-clock"
          title="Withdrawal"
          to="/withdrawal"
          exact
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="red-darken-4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logOutRequest" color="white">
        <v-icon left>mdi-logout</v-icon> Logout</v-btn
      >
      <!-- <v-btn @click="logOutRequest" color="white">
        <v-icon left>mdi-search-web</v-icon> Search</v-btn
      > -->
    </v-app-bar>

    <v-main class="app">
      <v-container fluid class="app">
        <router-view></router-view>
        <!--snackbar -->
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
          {{ snackbar.message }}
        </v-snackbar>

        <!--prompt-->
        <v-dialog v-model="RequestDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-button">
              {{ prompt }}
            </v-card-title>
            <v-card-text class="text-button"
              >Do You Want To Log Out</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="RequestDialog = false">
                No
              </v-btn>
              <v-btn
                :loading="isLoading"
                color="green darken-1"
                text
                @click="logOut"
              >
                Yes
                <template v-slot:loader>
                  <v-progress-circular indeterminate color="green" size="20" />
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
/* eslint-disable */
import { ref, reactive } from "vue";
import axios from "@/utils/axiosConfig";
import { useRouter } from "vue-router";

const RequestDialog = ref(false);
const drawer = ref(null);
const router = useRouter();
const isLoading = ref(false);

const snackbar = reactive({
  show: false,
  message: "",
  timeout: 3000,
});

const logOutRequest = () => {
  RequestDialog.value = true;
};

const logOut = async () => {
  isLoading.value = true;
  try {
    const response = await axios.post("/admin/logout");
    snackbar.message = "Successfully logged out !";
    snackbar.show = true;
    router.push({ name: "Login" });
  } catch (error) {
    isLoading.value = false;
    snackbar.message = "Failed to log out!";
    snackbar.show = true;
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  padding: 16px 0;
  text-align: center;
}

.v-list-item--nav .v-list-item-title {
  font-size: 1rem;
}

/* .MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
} */
.speedy-theme {
  color: #d13520;
  font-weight: 900;
}
</style>
